
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import UserAccess from "@/model/User/UserAccess";
import Multiselect from "vue-multiselect";
import TableHeader from "@/components/tableHeader.vue";
import IbotechService from "@/services/ibotechService";
import { WEP_MOCK_URL } from "@/shared/keycloak";
import * as XLSX from "xlsx";

// @ts-ignore
import * as d3 from "d3";

@Component({
  name: "SignMeasurement",
  components: { TableHeader, Multiselect },
  computed: {
    ...mapGetters([
      "getOrdersData",
      "getTotalOrdersList",
      "getUserAccess",
      "getCurrentUser",
      "isIbotechSignMeasurementUser",
      "isIbotechSignMeasurementAdmin"
    ])
  },
  methods: {
    ...mapActions(["getAllOrders", "getAllOrdersSearch"])
  },
  watch: {
    "form.x": "changeForm",
    "form.y": "changeForm",
    "form.surfaceColor.value": "changeForm"
  }
})
export default class SignMeasurement extends Vue {
  WEP_MOCK_URL = WEP_MOCK_URL;

  private getOrdersData!: Array<any>;
  private getTotalOrdersList!: number;

  readonly getUserAccess!: UserAccess;
  readonly getCurrentUser!: any;
  private readonly isIbotechSignMeasurementUser!: boolean;
  private readonly isIbotechSignMeasurementAdmin!: boolean;

  private getAllOrders!: Function;

  disabledRA: boolean = false;
  filterIsOpen: boolean = false;

  width: any = 650; // SVG Breite
  height: any = 550; // SVG Höhe
  margin: any = 40; // Rand für Achsen
  polygonCoords: any = {
    WHITE: [
      [0.305, 0.315],
      [0.335, 0.345],
      [0.325, 0.355],
      [0.295, 0.325],
      [0.305, 0.315]
    ],
    YELLOW: [
      [0.513, 0.437],
      [0.545, 0.454],
      [0.494, 0.505],
      [0.47, 0.48],
      [0.513, 0.437]
    ],
    RED: [
      [0.7, 0.25],
      [0.735, 0.265],
      [0.66, 0.34],
      [0.61, 0.34],
      [0.7, 0.25]
    ],
    BLUE: [
      [0.13, 0.09],
      [0.16, 0.09],
      [0.16, 0.14],
      [0.13, 0.14],
      [0.13, 0.09]
    ],
    GREEN: [
      [0.11, 0.415],
      [0.17, 0.415],
      [0.17, 0.5],
      [0.11, 0.5],
      [0.11, 0.415]
    ],
    BLACK: [
      [0.3, 0.27],
      [0.385, 0.355],
      [0.345, 0.395],
      [0.26, 0.31],
      [0.3, 0.27]
    ],
    ORANGE: [
      [0.535, 0.375],
      [0.61, 0.39],
      [0.57, 0.429],
      [0.506, 0.404],
      [0.535, 0.375]
    ],
    BROWN: [
      [0.479, 0.373],
      [0.558, 0.394],
      [0.523, 0.429],
      [0.455, 0.397],
      [0.479, 0.373]
    ],
    GREY_REF: [
      [0.305, 0.315],
      [0.335, 0.345],
      [0.325, 0.355],
      [0.295, 0.325],
      [0.305, 0.315]
    ],
    GREY_B: [
      [0.3, 0.31],
      [0.35, 0.36],
      [0.335, 0.375],
      [0.285, 0.325],
      [0.3, 0.31]
    ]
  };
  points: any = [];
  pointInsidePolygon: any = false;

  scannerRaPopoverOpen: any = false;
  scannerBetaPopoverOpen: any = false;
  scannerXYPopoverOpen: any = false;

  loading: any = false;
  currentPage: number = 1;
  perPage: number = 30;
  filter: string = "";
  searchInTableFor: string = "";
  editEntry: boolean = true;

  confirmData: any = {
    betaValid: false,
    passedRA: false,
    passedXY: false
  };
  checkResponse: boolean = false;
  checkButtonHide: boolean = false;

  surfaceOptions: Array<{ label: any; value: string }> = [
    { label: this.translateSurfaceOptions("white"), value: "WHITE" },
    { label: this.translateSurfaceOptions("yellow"), value: "YELLOW" },
    { label: this.translateSurfaceOptions("red"), value: "RED" },
    { label: this.translateSurfaceOptions("blue"), value: "BLUE" },
    { label: this.translateSurfaceOptions("green"), value: "GREEN" },
    { label: this.translateSurfaceOptions("black"), value: "BLACK" },
    { label: this.translateSurfaceOptions("orange"), value: "ORANGE" },

    // RA-Class not exists
    { label: this.translateSurfaceOptions("brown"), value: "BROWN" },
    { label: this.translateSurfaceOptions("gray_ref"), value: "GRAY_REF" },
    { label: this.translateSurfaceOptions("gray_b"), value: "GRAY_B" }
  ];

  raClassOptions: Array<{ label: string; value: string }> = [
    { label: "RA1", value: "RA1" },
    { label: "RA2", value: "RA2" },
    { label: "RA3", value: "RA3" }
  ];

  // TOOD: übersetzung
  fields: Array<string | any> = [
    {
      key: "selected",
      sortable: false,
      label: this.translateTableLabel("selected"),
      headlineGroup: this.translateTableLabel("header")
    },
    {
      key: "id",
      sortable: false,
      label: "ID",
      headlineGroup: this.translateTableLabel("header")
    },
    {
      key: "number",
      sortable: true,
      label: this.translateSignLabel("number"),
      headlineGroup: this.translateTableLabel("header")
    },
    {
      key: "project",
      sortable: true,
      label: this.translateSignLabel("projectNumber"),
      headlineGroup: this.translateTableLabel("header")
    },
    { key: "surfaceColor", label: this.translateSignLabel("color"), headlineGroup: this.translateTableLabel("header") },
    { key: "raClass", label: this.translateSignLabel("raClass"), headlineGroup: this.translateTableLabel("header") },

    { key: "x", label: "x", headlineGroup: this.translateSignLabel("colorMeasurement") },
    { key: "y", label: "y", headlineGroup: this.translateSignLabel("colorMeasurement") },
    {
      key: "pruefung_xy",
      label: this.translateSignLabel("measurement"),
      headlineGroup: this.translateSignLabel("colorMeasurement")
    },

    { key: "beta", label: "β", headlineGroup: this.translateSignLabel("lumianceFactor") },
    {
      key: "pruefung_beta",
      label: this.translateSignLabel("measurement"),
      headlineGroup: this.translateSignLabel("lumianceFactor")
    },

    { key: "raValue", label: "RA [cd·m⁻²·lx⁻¹]", headlineGroup: this.translateSignLabel("retroReflection") },
    {
      key: "pruefung_ra",
      label: this.translateSignLabel("measurement"),
      headlineGroup: this.translateSignLabel("retroReflection")
    },
    {
      key: "registrationTime",
      label: this.translateSignLabel("created"),
      headlineGroup: this.translateSignLabel("marginalData")
    },
    {
      key: "pruefer",
      label: this.translateSignLabel("inspector"),
      headlineGroup: this.translateSignLabel("marginalData")
    },
    {
      key: "comment",
      label: this.translateSignLabel("comment"),
      headlineGroup: this.translateSignLabel("marginalData")
    },
    {
      key: "actions",
      label: this.translateTableLabel("actions"),
      headlineGroup: this.translateSignLabel("marginalData")
    }
  ];

  filterForm: any = {
    surfaceColor: {
      label: "",
      value: ""
    },
    raClass: {
      label: "",
      value: ""
    },
    number: "",
    fromDate: ""
  };

  form: any = {
    lastName: "",
    firstName: "",
    number: "",
    project: "",
    comment: "",
    surfaceColor: {
      label: "",
      value: ""
    },
    raClass: {
      label: "",
      value: ""
    },
    x: "",
    y: "",
    beta: "",
    raValue: ""
  };

  created() {
    // Redirect to 404
    if (!this.getUserAccess.AccessIbotech.VIEW.signMeasurement) {
      this.$router.push({ name: "NotFound" });
    }

    this.fetchOrderData();
  }

  // --------------------------------------------------
  // --------------------------------------------------
  // --------------- Main Function --------------------
  // --------------------------------------------------
  // --------------------------------------------------

  async fetchOrderData() {
    this.loading = true;

    this.getAllOrders().then(() => {
      this.loading = false;
    });
  }

  getGroupedHeadlines() {
    const groups: Record<string, { label: string; colspan: number }> = {};
    this.fields.forEach((field) => {
      if (field.headlineGroup) {
        if (!groups[field.headlineGroup]) {
          groups[field.headlineGroup] = { label: field.headlineGroup, colspan: 0 };
        }
        groups[field.headlineGroup].colspan++;
      }
    });
    return Object.values(groups);
  }

  openSignMeasurementModal(action: any, data: any) {
    this.resetForm();
    this.editEntry = false;

    if (action == "edit") {
      this.editEntry = true;

      // Inputs
      this.form.id = data.item.id;
      this.form.number = data.item.number;
      this.form.x = data.item.x;
      this.form.y = data.item.y;
      this.form.beta = data.item.beta;
      this.form.raValue = data.item.raValue;
      this.form.comment = data.item.comment;

      // Selects
      this.form.surfaceColor = this.surfaceOptions.find((type) => type.value === data.item.surfaceColor);

      this.form.raClass = this.raClassOptions.find((type) => type.label === data.item.raClass);
    }

    this.$bvModal.show("newMeasurement");

    // Dont Touch it, if it works
    setTimeout(() => {
      this.changeSurfaceColor();
      this.renderChart();
    }, 100);
  }

  submitMeasurement() {
    if (this.isFormValid) {
      // TODO: wenn die werte false sind nochmal eine separate anfrage
      if (!this.confirmData.betaValid || !this.confirmData.passedRA || !this.confirmData.passedXY) {
        this.$bvModal.hide("newMeasurement");

        this.$bvModal
          .msgBoxConfirm((this as any).$t("ibotech_sign_measurement.submitPopupDescription"), {
            title: (this as any).$t("ibotech_sign_measurement.submitPopupTitle"),
            size: "md",
            buttonSize: "md",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0 bg-light",
            footerClass: "p-2 border-top-0",
            centered: true,
            okTitle: (this as any).$t("utils.alertBox.ok"),
            cancelVariant: "light",
            cancelTitle: (this as any).$t("utils.alertBox.cancel")
          })
          .then((value: boolean) => {
            if (value) {
              this.submitOrder();
            } else {
              this.$bvModal.show("newMeasurement");
            }
          });
      } else {
        this.submitOrder();
      }
    } else {
      this.error();
    }
  }

  async submitOrder() {
    this.form.raClass = this.form.raClass.value;
    this.form.surfaceColor = this.form.surfaceColor.value;

    let res = await IbotechService.addORder(this.form);

    if (res.status == 202) {
      this.$bvModal.hide("newMeasurement");
      this.resetForm();
      this.success();
      this.checkResponse = false;
      this.checkButtonHide = false;
      // this.fetchOrderData();

      this.fetchOrderData();
    } else {
      this.error();
    }
  }

  async confirmDeleteEntryMeasurement(id: any) {
    this.$bvModal
      .msgBoxConfirm((this as any).$t("ibotech_sign_measurement.deleteEntryDescription"), {
        title: (this as any).$t("ibotech_sign_measurement.deleteEntry"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          this.deleteOrder(id);
        }
      });
  }

  async deleteOrder(id: any) {
    let res = await IbotechService.deleteOrder(id);

    if (res.status == 200) {
      this.success();
      this.fetchOrderData();
    } else {
      this.error();
    }
  }

  // Was ist Pflicht
  get isFormValid() {
    return Object.entries(this.form).every(([label, data]) => {
      //
      var copyData: any = data;

      if (label === "id") return true; // ID darf leer sein
      if (label === "project") return true; // ID darf leer sein
      if (label === "comment") return true;

      if (typeof copyData === "object" && copyData !== null) {
        return copyData.value !== "" && copyData.value !== null && copyData.value !== undefined;
      }

      return copyData !== "" && copyData !== null && copyData !== undefined;
    });
  }

  async confirmSignData() {
    if (this.isFormValid) {
      var res = await IbotechService.check(this.form);

      if (res.status === 200) {
        this.checkResponse = true;
        this.checkButtonHide = true;
        this.confirmData = res.data;
      } else {
        this.error();
      }
    }
  }

  checkColor() {
    this.disabledRA = false;
    var color = this.form.surfaceColor.value;

    if (color === "BROWN" || color === "GRAY_REF" || color === "GRAY_B") {
      this.disabledRA = true;
      this.form.raClass = "";
      this.form.raValue = "";
    }
  }

  // --------------------------------------------------
  // --------------------------------------------------
  // --------------- Coordinate System ----------------
  // --------------------------------------------------
  // --------------------------------------------------

  changeForm() {
    this.updatePoints();
    this.renderChart();
  }

  getAxisRanges(color: any) {
    const axisRanges: any = {
      BLUE: { xDomain: [0.12, 0.17], yDomain: [0.08, 0.15] },
      RED: { xDomain: [0.608, 0.73], yDomain: [0.248, 0.348] },
      GREEN: { xDomain: [0.1, 0.18], yDomain: [0.4, 0.55] },
      BLACK: { xDomain: [0.25, 0.39], yDomain: [0.26, 0.4] },
      WHITE: { xDomain: [0.28, 0.34], yDomain: [0.3, 0.36] },
      ORANGE: { xDomain: [0.5, 0.62], yDomain: [0.36, 0.44] },
      YELLOW: { xDomain: [0.46, 0.55], yDomain: [0.42, 0.52] },
      BROWN: { xDomain: [0.44, 0.6], yDomain: [0.36, 0.44] },
      GREY_REF: { xDomain: [0.285, 0.345], yDomain: [0.3, 0.36] },
      GREY_B: { xDomain: [0.27, 0.36], yDomain: [0.3, 0.38] }
    };
    return axisRanges[color] || { xDomain: [0, 1], yDomain: [0, 1] };
  }

  updatePoints() {
    if (this.form.x && this.form.y) {
      const newPoint = { x: parseFloat(this.form.x), y: parseFloat(this.form.y) };
      this.points = [newPoint];

      if (this.form.surfaceColor.value !== "") {
        const polygon = this.polygonCoords[this.form.surfaceColor.value.toUpperCase()] || [];
        this.pointInsidePolygon = d3.polygonContains(polygon, [newPoint.x, newPoint.y]);

        this.renderChart();
      }
    }
  }

  renderChart() {
    if (this.$refs.chartContainer) {
      if (!this.form.surfaceColor.value) return;

      const container = this.$refs.chartContainer as HTMLElement | null;

      d3.select(container).selectAll("*").remove();

      const svg = d3.select(container).append("svg").attr("width", this.width).attr("height", this.height);

      const color: any = this.form.surfaceColor.value.toUpperCase();
      const { xDomain, yDomain } = this.getAxisRanges(color);
      const polygonData = this.polygonCoords[color] || [];

      const xScale = d3
        .scaleLinear()
        .domain(xDomain)
        .range([this.margin, this.width - this.margin]);

      const yScale = d3
        .scaleLinear()
        .domain(yDomain)
        .range([this.height - this.margin, this.margin]);

      // Pattern into Coordinate System
      const gridSize = (xDomain[1] - xDomain[0]) / 10;
      for (let x = xDomain[0]; x <= xDomain[1]; x += gridSize) {
        for (let y = yDomain[0]; y <= yDomain[1]; y += gridSize) {
          svg
            .append("rect")
            .attr("x", xScale(x))
            .attr("y", yScale(y + gridSize))
            .attr("width", xScale(gridSize) - xScale(0))
            .attr("height", yScale(0) - yScale(gridSize))
            .attr("fill", "none")
            .attr("stroke", "lightgray")
            .attr("stroke-width", 0.5);
        }
      }

      // X-Axis
      svg
        .append("g")
        .attr("transform", `translate(0,${this.height - this.margin})`)
        .call(d3.axisBottom(xScale).ticks(5));

      // Y-Axis
      svg.append("g").attr("transform", `translate(${this.margin},0)`).call(d3.axisLeft(yScale).ticks(5));

      // Polygon draw
      const lineGenerator = d3
        .line()
        .x((d: any) => xScale(d[0]))
        .y((d: any) => yScale(d[1]));

      svg
        .append("path")
        .datum(polygonData)
        .attr("d", lineGenerator)
        .attr("fill", "none")
        .attr("stroke", this.form.surfaceColor?.value || "blue")
        .attr("stroke-width", 3)
        .attr("stroke-dasharray", "4,4");

      // Custom point
      svg
        .selectAll(".user-point")
        .data(this.points)
        .enter()
        .append("circle")
        .attr("class", "user-point")
        .attr("cx", (d: any) => xScale(d.x))
        .attr("cy", (d: any) => yScale(d.y))
        .attr("r", 4)
        .attr("fill", "white")
        .attr("stroke", "white")
        .attr("stroke-width", 3);
    } else {
      console.error("chartContainer ist nicht verfügbar");
    }
  }

  get getData() {
    return this.getOrdersData;
  }

  // --------------------------------------------------
  // --------------------------------------------------
  // --------------- Helper Functions -----------------
  // --------------------------------------------------
  // --------------------------------------------------

  changeSurfaceColor() {
    this.confirmSignData();

    this.checkColor();
    this.renderChart();
  }

  getSurfaceColorLabel(value: any) {
    const option = this.surfaceOptions.find((option) => option.value === value);
    return option ? option.label : value;
  }

  success() {
    // Success Message
    this.$bvToast.toast(this.$t("ibotech_vehicle.newVehicleForm.successMessageText").toString(), {
      title: this.$t("ibotech_vehicle.newVehicleForm.successMessageTitle").toString(),
      autoHideDelay: 1500,
      variant: "success"
    });
  }

  error() {
    this.$bvToast.toast(this.$t("ibotech_vehicle.newVehicleForm.errorMessageText").toString(), {
      title: this.$t("ibotech_vehicle.newVehicleForm.errorMessageTitle").toString(),
      autoHideDelay: 1500,
      variant: "danger"
    });
  }

  closeModal() {
    this.$bvModal.hide("newMeasurement");
    this.resetForm();
  }

  resetForm() {
    this.form = {
      lastName: "",
      firstName: "",
      project: "",
      number: "",
      comment: "",
      surfaceColor: {
        label: "",
        value: ""
      },
      raClass: {
        label: "",
        value: ""
      },
      x: "",
      y: "",
      beta: "",
      raValue: ""
    };
  }

  translateTableLabel(label: string) {
    return this.$t(`ibotech_location.locationTableHeadlines.${label}`);
  }

  translateSignLabel(label: any) {
    return this.$t(`ibotech_sign_measurement.form.${label}`);
  }

  translateSurfaceOptions(label: any) {
    return this.$t(`ibotech_sign_measurement.surfaceOptions.${label}`);
  }

  // filterActionHandler(filterAction: string) {
  //   this.filter = filterAction;
  // }

  // customFilter(item, search) {
  //   const label = this.getSurfaceColorLabel(item.surfaceColor).toLowerCase();
  //   return label.includes(search.toLowerCase());
  // }

  onRowSelected(order: Array<any>) {
    this.$emit("rowSelected", order);
  }

  // ---------------------------------------
  // ---------------------------------------
  // -------- Filter and Export ------------
  // ---------------------------------------
  // ---------------------------------------

  searchOrders() {
    this.loading = true;

    console.log("FILTER", this.filterForm);

    this.getAllOrders(this.filterForm).then(() => {
      this.loading = false;
    });

    // if (res.status == 200) {
    //   this.success();
    //   this.fetchOrderData();
    //
    // } else {
    //   this.error();
    // }
  }

  handleSurfaceColorChange(value: any) {
    // Falls nichts mehr ausgewählt ist, setze es auf null oder []
    if (!value || value.length === 0) {
      this.filterForm.surfaceColor = {
        label: "",
        value: ""
      }; // Oder []
    }
  }

  handleRaClassChange(value: any) {
    // Falls nichts mehr ausgewählt ist, setze es auf null oder []
    if (!value || value.length === 0) {
      this.filterForm.raClass = {
        label: "",
        value: ""
      }; // Oder []
    }
  }

  resetFilter() {
    this.fetchOrderData();

    this.filterForm = {
      surfaceColor: {
        label: "",
        value: ""
      },
      raClass: {
        label: "",
        value: ""
      },
      number: "",
      fromDate: ""
    };
  }

  // ----------------------------------------
  // ----------------------------------------
  // ----------- Export Data ----------------
  // ----------------------------------------
  // ----------------------------------------

  exportRecordsData() {
    // Definieren der gewünschten Reihenfolge der Schlüssel
    const desiredOrder: string[] = [
      "id",
      "project",
      "number",
      "surfaceColor",
      "raClass",
      "x",
      "y",
      "passedxy",
      "beta",
      "betavalid",
      "raValue",
      "passedra",
      "registrationTime",
      "comment",
      "lastName",
      "firstName"
    ];

    // Sortieren der Daten gemäß der gewünschten Reihenfolge
    const sortedData = this.getData.map((item: { [key: string]: any }) => {
      const sortedItem: { [key: string]: any } = {};
      desiredOrder.forEach((key: string) => {
        sortedItem[key] = item[key];
      });
      return sortedItem;
    });

    this.convertToXLSX(sortedData);
  }

  convertToXLSX(data: any) {
    // Holen der Schlüssel aus dem ersten Datenobjekt und Übersetzen der Header
    const keys = Object.keys(data[0]);
    const translatedKeys = keys.map((key) => this.$t(`ibotech_sign_measurement.excelReport.${key}`).toString());

    // Formatieren der Datenzeilen
    const formattedData = this.formattedData(keys, data);

    // Erstellen des Arbeitsblatts
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: translatedKeys });

    // Anpassen der Spaltenbreiten
    const columnWidths = this.calculateColumnWidth(translatedKeys, formattedData);
    ws["!cols"] = columnWidths;

    // Erstellen der Arbeitsmappe
    const wb = XLSX.utils.book_new();

    // Download der Excel-Datei
    this.downloadExcel(wb, ws);
  }

  downloadExcel(workbook: XLSX.WorkBook, worksheet: XLSX.WorkSheet) {
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "export.xlsx");
  }

  calculateColumnWidth(headers: string[], data: { [key: string]: any }[]): { wch: number }[] {
    return headers.map((header) => {
      const columnData = data.map((row) => (row[header] ? row[header].toString().length : 0));
      const headerLength = header.length;
      const maxLength = Math.max(headerLength, ...columnData);
      return { wch: maxLength + 2 };
    });
  }

  formattedData(keys: string[], data: any[]): { [key: string]: any }[] {
    return data.map((row: any) => {
      const formattedRow = { ...row };
      formattedRow.registrationTime = this.formatDateIntoGermanDate(row.registrationTime);
      formattedRow.passedxy = this.translateCheckData(row.passedxy);
      formattedRow.passedra = this.translateCheckData(row.passedra);
      formattedRow.betavalid = this.translateCheckData(row.betavalid);
      formattedRow.surfaceColor = this.getSurfaceColorLabel(row.surfaceColor);
      return keys.reduce((acc: { [key: string]: any }, key: string) => {
        acc[this.translateExportLabel(key)] = formattedRow[key];
        return acc;
      }, {});
    });
  }

  translateExportLabel(label: any) {
    return this.$t(`ibotech_sign_measurement.excelReport.${label}`).toString();
  }

  formatDateIntoGermanDate(dateString: string): string {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    });
    const formattedTime = date.toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit"
    });
    return `${formattedDate} ${formattedTime}`;
  }

  translateCheckData(value: boolean): string {
    return value ? "Erfolgreich" : "Fehlerhaft";
  }

  get deleteEntryRight() {
    return this.getUserAccess.AccessIbotech.VIEW.signMeasurementDelete;
  }
}
